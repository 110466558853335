/*
 * @Author: ruiwang
 * @Date: 2020-04-29 14:44:08
 * @Last Modified by: ruiwang
 * @Last Modified time: 2020-04-29 17:06:00
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    getByKey: {
        url: '/api/operation/staticText/getByKey',
        method: 'get'
    }
};

export default Resource.create(api);
