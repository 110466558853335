<!-- @author xiaodongyu -->
<!-- @date 2019/8/1-20:08 -->
<!-- @desc index -->

<template>
    <yqg-md-preview
        :md-text="content"
        class="static-text"
    />
</template>

<script type="text/babel">
import asyncContentManage from 'easycash/common/mixin/async-content-manage';
import StaticText from 'easycash/common/resource/static-text';

export default {
    name: 'StaticText',

    mixins: [asyncContentManage({query: StaticText.getByKey})]
};
</script>
<style lang="scss" rel="stylesheet/scss">
.static-text {
    p {
        margin-bottom: 16px;
    }
}
</style>
