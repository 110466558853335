var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yqg-md-preview", {
    staticClass: "static-text",
    attrs: { "md-text": _vm.content },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }